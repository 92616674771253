const NotFound = () => {
  return (
    <div>
      <h2>
        Ups... looks like your lost in interior...Such page was not found, try
        again!
      </h2>
    </div>
  );
};

export default NotFound;
